import { Paginated } from '@feathersjs/feathers'
import moment from 'moment-timezone'
import { TIMEZONE } from '../constants'
import { IDateRangePair } from '../store/types'
import { Interval, DateTime } from 'luxon'

export type FeathersResult<T> = T | T[] | Paginated<T>

/**
 * Extract the first result from feathers
 * @param result
 */
export function getFirstResult<T> (result: FeathersResult<T> | undefined): T | null {
  if (Array.isArray(result)) {
    return result[0] || null
  } else if (result && 'total' in result && 'limit' in result && 'skip' in result && 'data' in result) {
    return result.data[0] || null
  }
  return result || null
}

/**
 * Normalise a feathers returned result
 * @param result
 */
export function feathersResultToArray<T> (result: FeathersResult<T> | undefined): T[] {
  if (Array.isArray(result)) {
    return result
  } else if (result && 'total' in result && 'limit' in result && 'skip' in result && 'data' in result) {
    return result.data
  }
  return result ? [result] : []
}

/**
 * Round the given number up based upon the decimal places
 * @param number number to round up
 * @param places number of decimal places to round up to (defaults to 2)
 */
export const roundNumberUp = (number: number, places = 2): number => {
  return Math.ceil(number * (10 ** places)) / (10 ** places)
}

/**
 * Round the given number down based upon the decimal places
 * @param number number to round down
 * @param places number of decimal places to round up to (defaults to 2)
 */
export const roundNumberDown = (number: number, places = 2) => {
  return Math.floor(number * (10 ** places)) / (10 ** places)
}

/**
 * Round the given number based upon the decimal places
 * @param number number to round
 * @param places number of decimal places to round up to (defaults to 2)
 */
export const roundNumber = (number: number, places = 2) => {
  return Math.round(number * (10 ** places)) / (10 ** places)
}

export const rangeFromObject = ({ validFrom, validTo }: IDateRangePair): {
  contains(time: moment.Moment): boolean
} => {
  // always return true if no times defined
  if (!validFrom && !validTo) {
    return {
      contains() {
        return true
      }
    }
  }
  // typings for moment-range are wrong
  const i = Interval.fromDateTimes(
    validFrom ? moment.tz(validFrom, TIMEZONE).startOf('day').toDate() : DateTime.fromISO('1900-01-01'),
    validTo ? DateTime.fromJSDate(moment.tz(validTo, TIMEZONE).endOf('day').toDate()).plus({ millisecond: 1 }) : DateTime.fromISO('3000-01-01')
  )
  if (!i.isValid) {
    throw new Error(`Interval not value ${validFrom} - ${validTo} ${i.invalidExplanation} ${i.invalidReason}`)
  }
  return {
    contains(m) {
      return i.contains(DateTime.fromJSDate(m.toDate()))
    }
  }
}
